/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --direction-page: ltr;
  --flex-row: row;
  --flex-item: row;
  --text-item: left;
  --disabled-color: #B0B0B0;
  --disabled-button-bg: #D3D3D3;
  --disabled-button-color: #8C8C8C;
}

.dynamic-align {
  text-align: var(--text-item);
}

.dynamic-flex {
  flex-direction: var(--flex-row);
}

@layer base {
  body {
    color: var(--tg-theme-text-color, black);
    color-scheme: var(--tg-color-scheme);
    background-color: var(--tg-theme-bg-color);
  }

  *, input, textarea {
    border-color: var(--tg-theme-text-color, black);
  }

  svg {
    fill: var(--tg-theme-text-color, black)
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='radio'] {
    @apply border-custom-gray-5 w-[14px] h-[14px] focus:ring-2 focus:ring-custom-turquoise checked:bg-none checked:ring-2 ring-2 ring-offset-2 ring-custom-gray-5 checked:ring-custom-turquoise;
    color: #3f9aa1;
  }

  input[type='range'] {
    @apply h-1 bg-custom-gray-green rounded-lg appearance-none cursor-pointer accent-custom-turquoise;
  }

  input[type='range' i]::-webkit-slider-thumb {
    @apply w-5 h-5 bg-custom-turquoise sm:h-5 sm:w-5 rounded-full;
    -webkit-appearance: none;
  }

  input[type='range']::-moz-range-thumb,
  input[type='range']::-ms-thumb,
  input[type='range']::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
  }
}

@layer utilities {
  .custom-shadow-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .custom-shadow-2 {
    box-shadow: 0 4px 6px 0 rgba(143, 31, 98, 0.12);
  }

  .custom-shadow-3 {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  }

  .button-shadow-1 {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .filter-drop-shadow-1 {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
  }

  .filter-drop-shadow-2 {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12));
  }

  .popup-shadow {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
  }

  .secondary-bg {
    background-color: var(--tg-theme-secondary-bg-color);
  }

  .main-bg {
    background-color: var(--tg-theme-bg-color);
  }

  .bg-text-color {
    background-color: var(--tg-theme-text-color, black);
  }
}

@layer components {
  html {
    @apply text-custom-black;
  }

  * {
    @apply font-montserrat;
  }
}

@layer utilities {
  .custom-shadow-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@layer components {
  /********************************
            TYPOGRAPHY
            ********************************/
  .h1 {
    @apply text-[4rem] font-light leading-[6.25rem] tracking-normal;
  }

  .h2 {
    @apply text-5xl font-medium leading-[4.5rem] tracking-[-2px];
  }

  .h3 {
    @apply text-4xl font-normal leading-[3.375rem];
  }

  .h4 {
    @apply text-2xl font-medium leading-9;
  }

  .h5 {
    @apply text-2xl font-normal leading-9;
  }

  .body1 {
    @apply text-xl font-normal leading-[1.875rem];
  }

  .body2 {
    @apply text-base font-normal leading-[1.6rem];
  }

  .body3 {
    @apply text-sm font-normal leading-[1.313rem];
  }

  .caption {
    @apply text-xs font-bold leading-[1.125rem];
  }

  .title {
    @apply text-3xl sm:text-4xl font-bold capitalize;
  }

  .body {
    @apply text-lg font-source-sans;
  }

  /********************************
            BUTTON
            ********************************/
  .btn {
    @apply cursor-pointer transition-all ease-linear flex items-center justify-center gap-3 px-6 py-2 sm:py-3 body2 rounded capitalize leading-6 tracking-[-0.02em];
  }

  .btn-text {
    @apply bg-transparent shadow-none hover:bg-custom-gray-6 active:bg-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-outlined {
    @apply border disabled:border-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-outlined-pink {
    @apply btn-outlined border-custom-pink
  }

  .btn-outlined-teal {
    @apply btn-outlined border-custom-teal
  }

  .btn-outlined-yellow {
    @apply btn-outlined border-custom-yellow
  }

  .btn-yellow {
    @apply text-custom-gray-1 bg-custom-yellow hover:bg-custom-yellow-hover active:bg-custom-yellow-click disabled:bg-custom-gray-4 disabled:text-white;
  }

  .btn-pink {
    @apply text-white bg-custom-pink hover:bg-custom-pink-hover active:bg-custom-pink-click disabled:bg-custom-gray-4;
  }

  .btn-outlined-red {
    @apply text-custom-error-red border border-custom-error-red hover:bg-custom-error-red hover:bg-opacity-5;
  }

  .btn-telegram {
    @apply text-white bg-gradient-to-b from-custom-telegram-1 to-custom-telegram-2;
  }

  .btn-sm {
    @apply px-6 py-1;
  }

  .btn-md {
    @apply px-6 py-2;
  }

  .btn-lg {
    @apply px-6 py-3;
  }

  .btn > svg {
    @apply fill-current;
  }

  .bg-theme {
    background: var(--tg-theme-bg-color, white)
  }
}
